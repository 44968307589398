<template>
  <div class="service__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <div class="d-flex">
          <h1 class="custom-header-title">
            Dashboard - {{ dashboard.name }}
          </h1>

          <b-button
            v-if="canViewThisAction('update', 'Dashboard')"
            variant="flat-primary"
            class="ml-auto bg-white"
            :to="{ name: 'dashboard-views-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-tabs
      pills
      class="dashboard__tabs"
    >
      <b-tab active>
        <template #title>
          <b-card
            no-body
            class="dashboard__tab-card"
          >
            <b-card-body
              class="d-flex align-items-center"
            >
              <b-avatar
                variant="light-primary"
                size="40"
                rounded
              >
                <feather-icon
                  size="20"
                  icon="PieChartIcon"
                  class="mr-0"
                />
              </b-avatar>
              <div class="truncate ml-2">
                <h2>Overview</h2>
              </div>
            </b-card-body>
          </b-card>
        </template>

        <b-card class="dashboard__tab-content-card">
          <div class="d-flex justify-content-between">
            <p class="tab-sub-title">
              An overview of statistical data from your assigned modules.
            </p>
          </div>
          <hr>
          <div
            v-if="!overviewWidgets.length"
            class="dashboard_empty_block-wrapper"
          >
            <div class="dashboard_empty_block">
              <h4>There’s nothing here yet</h4>
            </div>
          </div>
          <section v-else>
            <div class="match-height">
              <b-row class="match-height">
                <template
                  v-for="(widget, key) in overviewWidgets"
                >
                  <MonthlyBookingCounts
                    v-if="widget.subModule == 'Current month: Booking Activity'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <DailyBookingCounts
                    v-else-if="widget.subModule == 'Today only: Booking Status'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceFormStatusCount
                    v-else-if="widget.subModule == 'Digital Service Form status'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceFormAgeCount
                    v-else-if="widget.module == 'service forms' && widget.subModule == 'Pending Status Aging Days'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceRequestThirtyDays
                    v-else-if="widget.subModule == 'Last 30D: Pending status'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceRequestToday
                    v-else-if="widget.subModule == 'Today only: Pending status'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceRequestAgeCount
                    v-else-if="widget.module == 'service requests' && widget.subModule == 'Pending Status Aging Days'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceRequestQuarterlyPayment
                    v-else-if="widget.subModule == 'Quarterly Payment Status'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <ServiceRequestAwaitingApproval
                    v-else-if="widget.subModule == 'Awaiting Approval Status'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <InventoryRequestsByStore
                    v-else-if="widget.subModule == 'Stores'"
                    :key="key"
                    :year-option="Number(widget.year)"
                    :store-options="widget.stores"
                    class="my-dashboard__card-wrapper"
                  />
                  <InventoryRequestsByStatus
                    v-else-if="widget.subModule == 'Status'"
                    :key="key"
                    :year-option="Number(widget.year)"
                    :store-options="widget.stores"
                    class="my-dashboard__card-wrapper"
                  />
                  <PurchaseRequestCount
                    v-else-if="widget.subModule == 'PRF'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <QuotationCount
                    v-else-if="widget.subModule == 'QCF'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <PurchaseOrderCount
                    v-else-if="widget.subModule == 'PO'"
                    :key="key"
                    class="my-dashboard__card-wrapper"
                  />
                  <BudgetOverview
                    v-else-if="widget.module == 'budget overview'"
                    :key="key"
                    :cost-center="widget.costCenter"
                    :budgetary-period="widget.budgetaryPeriod"
                    :expense-category-type="widget.expenseCategoryType"
                    :expense-categories="widget.expenseCategories"
                    class="my-dashboard__card-wrapper"
                  />
                </template>
              </b-row>
            </div>
          </section>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <b-card
            no-body
            class="dashboard__tab-card"
          >
            <b-card-body
              class="d-flex align-items-center"
            >
              <b-avatar
                variant="light-primary"
                size="40"
                rounded
              >
                <feather-icon
                  size="20"
                  icon="GridIcon"
                  class="mr-0"
                />
              </b-avatar>
              <div class="truncate ml-2">
                <h2>My Dashboard</h2>
              </div>
            </b-card-body>
          </b-card>
        </template>
        <b-card class="dashboard__tab-content-card">
          <div class="d-flex justify-content-between">
            <p class="tab-sub-title">
              The following items require your action or follow-up.
            </p>
          </div>
          <hr>
          <div
            v-if="!myDashboardWidgets.length"
            class="dashboard_empty_block-wrapper"
          >
            <div class="dashboard_empty_block">
              <h4>There’s nothing here yet</h4>
            </div>
          </div>
          <section v-else>
            <b-row>
              <b-col
                v-for="(widget, key) in myDashboardWidgets"
                :key="key"
                lg="12"
                class="todo-item p-0"
              >
                <div
                  v-if="widget.module == 'Bookings' && canViewThisAction('read', 'Booking')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <BookingsList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
                <div
                  v-else-if="widget.module == 'Service Forms' && canViewThisAction('read', 'ServiceForm')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <ServiceFormList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :form-status-options="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
                <div
                  v-else-if="widget.module == 'Service Requests' && canViewThisAction('read', 'ServiceRequest')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <ServiceRequestList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :payment-status-options="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
                <div
                  v-else-if="widget.module == 'Inventory Requests' && canViewThisAction('read', 'InventoryRequest')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <InventoryRequestList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :store-options="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
                <div
                  v-else-if="widget.module == 'Purchase Requests' && canViewThisAction('read', 'PurchaseRequest')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <PurchaseRequestList
                    :widget-name="widget.widgetName"
                    :budget-status-options="widget.statusOptions"
                    :request-type="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
                <div
                  v-else-if="widget.module == 'Vendor Payments' && canViewThisAction('read', 'VendorPayment')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <VendorPaymentList
                    :widget-name="widget.widgetName"
                    :request-type="widget.statusOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
                <div
                  v-else-if="widget.module == 'Vendors List' && canViewThisAction('read', 'Vendor')"
                  :key="key"
                  class="overview-widget__single-block m-0 table__card-transparent-bg mb-2"
                >
                  <VendorList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                  />
                  <hr
                    class="mx-1"
                  >
                </div>
              </b-col>
            </b-row>
          </section>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <b-card
            no-body
            class="dashboard__tab-card"
          >
            <b-card-body
              class="d-flex align-items-center"
            >
              <b-avatar
                variant="light-primary"
                size="40"
                rounded
              >
                <feather-icon
                  size="20"
                  icon="UsersIcon"
                  class="mr-0"
                />
              </b-avatar>
              <div class="truncate ml-2">
                <h2>Department Dashboard</h2>
              </div>
            </b-card-body>
          </b-card>
        </template>
        <b-card class="dashboard__tab-content-card">
          <div class="d-flex justify-content-between">
            <p class="tab-sub-title">
              These are outstanding items for your department(s).
            </p>
          </div>
          <hr>
          <div
            v-if="!departmentWidgets.length"
            class="dashboard_empty_block-wrapper"
          >
            <div class="dashboard_empty_block">
              <h4>There’s nothing here yet</h4>
            </div>
          </div>
          <section v-else>
            <b-tabs
              v-model="departmentSubTab"
              pills
              class="department-view__tabs"
            >
              <template
                v-for="(widget, key) in departmentWidgets"
              >
                <b-tab
                  v-if="widget.module == 'Bookings'"
                  :key="key"
                >
                  <template #title>
                    <span>Bookings <span class="rounded-number">{{ bookingsCount }}</span></span>
                  </template>
                  <DepartmentBookingsList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :bookings-count.sync="bookingsCount"
                  />
                </b-tab>
                <b-tab
                  v-else-if="widget.module == 'Service Forms'"
                  :key="key"
                >
                  <template #title>
                    <span>Service Forms <span class="rounded-number">{{ serviceFormsCount }}</span></span>
                  </template>
                  <DepartmentServiceFormList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :payment-status-options="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :service-forms-count.sync="serviceFormsCount"
                  />
                </b-tab>
                <b-tab
                  v-else-if="widget.module == 'Service Requests'"
                  :key="key"
                >
                  <template #title>
                    <span>Service Requests <span class="rounded-number">{{ serviceRequestsCount }}</span></span>
                  </template>
                  <DepartmentServiceRequestList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :payment-status-options="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :service-requests-count.sync="serviceRequestsCount"
                  />
                </b-tab>
                <b-tab
                  v-else-if="widget.module == 'Inventory Requests'"
                  :key="key"
                >
                  <template #title>
                    <span>Inventory Requests <span class="rounded-number">{{ inventoryRequestsCount }}</span></span>
                  </template>
                  <DepartmentInventoryRequestList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :store-options="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :inventory-requests-count.sync="inventoryRequestsCount"
                  />
                </b-tab>
                <b-tab
                  v-else-if="widget.module == 'Purchase Requests'"
                  :key="key"
                >
                  <template #title>
                    <span>Purchase Requests <span class="rounded-number">{{ purchaseRequestsCount }}</span></span>
                  </template>
                  <DepartmentPurchaseRequestList
                    :widget-name="widget.widgetName"
                    :budget-status-options="widget.statusOptions"
                    :request-type="widget.extraOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :purchase-requests-count.sync="purchaseRequestsCount"
                  />
                </b-tab>
                <b-tab
                  v-else-if="widget.module == 'Vendor Payments'"
                  :key="key"
                >
                  <template #title>
                    <span>Vendor Payments <span class="rounded-number">{{ vendorPaymentsCount }}</span></span>
                  </template>
                  <DepartmentVendorPaymentList
                    :widget-name="widget.widgetName"
                    :request-type="widget.statusOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :vendor-payments-count.sync="vendorPaymentsCount"
                  />
                </b-tab>
                <b-tab
                  v-else-if="widget.module == 'Vendors List'"
                  :key="key"
                >
                  <template #title>
                    <span>Vendors List <span class="rounded-number">{{ vendorsCount }}</span></span>
                  </template>
                  <DepartmentVendorList
                    :widget-name="widget.widgetName"
                    :status-options="widget.statusOptions"
                    :filter-option="widget.filterBy"
                    :date-option="widget.dataFreshness"
                    :date-range-option="widget.dateRangeOption"
                    :table-columns="widget.tableColumns"
                    :vendors-count.sync="vendorsCount"
                  />
                </b-tab>
              </template>
            </b-tabs>
          </section>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BTabs,
  BTab,
  BAvatar,
  VBTooltip,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  // eslint-disable-next-line import/named
  required,
} from '@validations'
import store from '@/store/index'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MonthlyBookingCounts from '../widgets/overview/MonthlyBookingCounts.vue'
import DailyBookingCounts from '../widgets/overview/DailyBookingCounts.vue'
import ServiceFormStatusCount from '../widgets/overview/ServiceFormStatusCount.vue'
import ServiceFormAgeCount from '../widgets/overview/ServiceFormAgeCount.vue'
import ServiceRequestThirtyDays from '../widgets/overview/ServiceRequestThirtyDays.vue'
import ServiceRequestToday from '../widgets/overview/ServiceRequestToday.vue'
import ServiceRequestAgeCount from '../widgets/overview/ServiceRequestAgeCount.vue'
import ServiceRequestQuarterlyPayment from '../widgets/overview/ServiceRequestQuarterlyPayment.vue'
import ServiceRequestAwaitingApproval from '../widgets/overview/ServiceRequestAwaitingApproval.vue'
import InventoryRequestsByStore from '../widgets/overview/InventoryRequestsByStore.vue'
import InventoryRequestsByStatus from '../widgets/overview/InventoryRequestsByStatus.vue'
import PurchaseRequestCount from '../widgets/overview/PurchaseRequestCount.vue'
import QuotationCount from '../widgets/overview/QuotationCount.vue'
import PurchaseOrderCount from '../widgets/overview/PurchaseOrderCount.vue'
import BudgetOverview from '../widgets/overview/BudgetOverview.vue'
import BookingsList from '../widgets/my-dashboard/BookingsList.vue'
import InventoryRequestList from '../widgets/my-dashboard/InventoryRequestList.vue'
import PurchaseRequestList from '../widgets/my-dashboard/PurchaseRequestList.vue'
import ServiceFormList from '../widgets/my-dashboard/ServiceFormList.vue'
import ServiceRequestList from '../widgets/my-dashboard/ServiceRequestList.vue'
import VendorList from '../widgets/my-dashboard/VendorList.vue'
import VendorPaymentList from '../widgets/my-dashboard/VendorPaymentList.vue'
import DepartmentBookingsList from '../widgets/department-dashboard/BookingsList.vue'
import DepartmentInventoryRequestList from '../widgets/department-dashboard/InventoryRequestList.vue'
import DepartmentPurchaseRequestList from '../widgets/department-dashboard/PurchaseRequestList.vue'
import DepartmentServiceFormList from '../widgets/department-dashboard/ServiceFormList.vue'
import DepartmentServiceRequestList from '../widgets/department-dashboard/ServiceRequestList.vue'
import DepartmentVendorList from '../widgets/department-dashboard/VendorList.vue'
import DepartmentVendorPaymentList from '../widgets/department-dashboard/VendorPaymentList.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    MonthlyBookingCounts,
    DailyBookingCounts,
    ServiceFormStatusCount,
    ServiceFormAgeCount,
    ServiceRequestThirtyDays,
    ServiceRequestToday,
    ServiceRequestAgeCount,
    ServiceRequestQuarterlyPayment,
    ServiceRequestAwaitingApproval,
    InventoryRequestsByStore,
    InventoryRequestsByStatus,
    PurchaseRequestCount,
    QuotationCount,
    PurchaseOrderCount,
    BudgetOverview,
    BookingsList,
    InventoryRequestList,
    PurchaseRequestList,
    ServiceFormList,
    ServiceRequestList,
    VendorList,
    VendorPaymentList,
    DepartmentBookingsList,
    DepartmentInventoryRequestList,
    DepartmentPurchaseRequestList,
    DepartmentServiceFormList,
    DepartmentServiceRequestList,
    DepartmentVendorList,
    DepartmentVendorPaymentList,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      topSpacing: 135,
      dashboard: {},
      overviewWidgets: [],
      myDashboardWidgets: [],
      departmentWidgets: [],
      departmentSubTab: 0,
      bookingsCount: 0,
      serviceFormsCount: 0,
      serviceRequestsCount: 0,
      inventoryRequestsCount: 0,
      purchaseRequestsCount: 0,
      vendorPaymentsCount: 0,
      vendorsCount: 0,

      required,
    }
  },
  created() {
    this.$http.get(`user/dashboard-views/${this.$route.params.id}/show`)
      .then(response => {
        this.dashboard = response.data.data || {}
        this.overviewWidgets = response.data.data.overview || []
        this.myDashboardWidgets = response.data.data.myDashboard || []
        this.departmentWidgets = response.data.data.departmentDashboard || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
.apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  margin-left: 0rem !important;
  padding-left: 3px !important;
}
</style>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*="btn-outline-"]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
